.search
  z-index: 1
  margin: 50px 0

  h1
    text-align: center
    font-size: 1em

  input
    text-align: center
    font-size: 1em
    height: 4vh
    border-radius: 50px