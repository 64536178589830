.movie-catalogo
  display: flex !important
  justify-content: center !important 
  margin-top: 2%

  .ant-card-cover
    img
      height: 350px !important

  .ant-card-meta-title
    text-align: center !important 


