.slider-movies
  height: 400px

  &__movie
    height: 60vh
    background-size: cover
    background-repeat: no-repeat
    background-position: center top

    &-info
      display: flex
      align-items: flex-end
      height: 100%
      padding-bottom: 40px
      opacity: .9

      > div 
        background-color: #a7a7a7cf
        margin: 0 auto
        text-align: center
        width: 50%
        padding: 20px