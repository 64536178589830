.movie-list
  width: 80%
  margin: 0 auto !important
  margin-top: 50px !important
  border-color: #001529 !important


  .ant-list-header
    background-color: #001529

    h2
      text-align: center
      margin: 0
      color: white 


  .ant-list-items
    height: 60vh
    overflow: scroll

  &__movie
    .ant-list-item-meta
      align-items: center

    .ant.list.item-meta-title
      margin: 0