.footer
  display: flex
  align-items: center
  justify-content: center
  background-color: #001529 !important
  margin-top: 5%
  padding: 1% 0 !important

  p
    margin: 0
    color: white