.modal-video
  width: 100% !important
  height: 100% !important
  padding: 0 !important

  .ant-modal-content
    height: 100%
    background-color: black !important

    .ant-modal-close
      color: white

      span
        font-size: 1em
        margin-right: 20%
        margin-top: 20%

  .ant-modal-body
    height: 100%
    display: flex
    align-items: center
    justify-content: center

    > div
      width: 90% !important
      height: 90% !important

